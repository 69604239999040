import {createRouter, createWebHashHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from "../views/LoginView.vue"
import BoardPage from "@/views/BoardPage.vue";
import UserManage from "@/views/UserManage.vue";
import AppManage from "@/views/AppManage.vue"
import KeywordSet from "@/views/KeywordSet.vue"

const routes = [
    {
        path: '/',
        name: 'login',
        component: LoginView
    },
    {
        path: '/home',
        name: 'home',
        component: HomeView,
        redirect: '/board',
        children: [
            {
                path: '/board',
                name: 'board',
                component: BoardPage
            },
            {
                path: '/userManage',
                name: 'userManage',
                component: UserManage
            },
            {
                path: '/appManage',
                name: 'appManage',
                component: AppManage
            },
            {
                path: '/KeywordSet',
                name: 'KeywordSet',
                component: KeywordSet
            }
        ]
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
